@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.VerifiedPharmacy {
  border: 1px solid colors.$gray2;
  max-width: 450px;
  width: 100%;
  background: colors.$white;
  margin: 5px auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;

  .headline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: colors.$green;

    .title-left {
      font-size: 20px;
      margin-right: 10px;
    }

    .title-right {
      font-size: 14px;
    }
  }

  .details-line {
    align-self: center;
    border-top-color: colors.$green;
    border-top-width: 1px;
    border-top-style: solid;
    width: calc(100% - 8px);
  }

  .rating-row {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem;
    color: colors.$gray2;
    align-items: center;
    align-content: center;
    align-self: center;
    text-align: center;

    .verified {
      color: colors.$green;
      display: flex;
      margin-top: -3px;
    }
  }
}

.Details {
  .button {
    align-items: center;
    padding: 8px 20px;
    margin: 10px;
    border-radius: 25px;
    width: fit-content;
    overflow: hidden;

    .text {
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.25px;
    }
  }

  .divider-green {
    border-bottom-color: colors.$green;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 5px 5px 15px;
    width: calc(100% - 8px);

    .details-button {
      background-color: colors.$green;
      margin-left: 0;

      .text {
        color: colors.$white;
      }
    }

    .pharmacy-button {
      background-color: colors.$white;
      margin-right: 0;
      border: 1px solid colors.$green;

      .text {
        color: colors.$green;
      }
    }
  }
}

@media (max-width: 360px) {
  .VerifiedPharmacy {
    .verified {
      font-size: 12px;
    }
  }
}
