@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.Availability {
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4rem;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  h3 {
    text-align: center;
    color: colors.$green;
    margin-bottom: .5rem;
    margin-top: 0;
  }

  .question {
    margin-bottom: 1rem;
    text-align: center;
  }

  .choices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    .radio-button-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .choice {
        margin-top: 1rem;
      }
    }
  }

  .remark {
    color: colors.$gray7;
    text-align: center;
    margin-bottom: 2rem;

    .bold {
      font-weight: 700;
    }
  }

  .medication-wrapper {
    background-color: colors.$gray3;
    margin-top: 1.4rem;
    margin-bottom: 2rem;
    text-align: center;
    padding: .8rem;

    p {
      color: colors.$gray0;
      margin-bottom: 1rem;
    }
  }

  .medication-name {
    margin-bottom: .5rem;
    color: colors.$black;
    font-weight: bold;
  }
  .button-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .button {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
