@use '../../../styles/variables/colors';

.ProgressSteps {
    justify-content: space-between;
    align-items: start;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    counter-reset: progress;
    position: relative;
    font-size: 16px;
    line-height: 1.2;
    margin: 0 0 2rem;

    &::before {
        height: 1px;
        position: absolute;
        top: 1.5em;
        left: 3rem;
        right: 3rem;
        background-color: colors.$gray7;
        display: block;
        content: '';
    }

    li {
        z-index: 1;
        font-weight: normal;
        width: 5.5em;
        padding: 0;
        margin: 0;
        justify-content: center;
        align-items: center;
        list-style-type: none;
        color: colors.$gray0;
        text-align: center;
        position: relative;

        .ProgressStep {
            background-color: colors.$gray0;
            color: colors.$white;
            display: block;
            margin: 0 auto;
            padding: 0.25em;
            width: 1.5em;
            height: 1.5em;
            font-size: 1em;
            line-height: 1.5;
            border-radius: 2em;
            text-align: center;
            border: 0.5em solid colors.$white;
            box-sizing: content-box;
        }

        .ProgressTitle {
            font-size: 0.75em;
        }

        &.ProgressActive {
            color: colors.$green;
            font-weight: bold;

            .ProgressStep {
                background-color: colors.$green;
                font-weight: bold;
            }
        }
    }

}

@media (max-width: 512px) {
    .ProgressSteps {
        font-size: 12px;
    }
}